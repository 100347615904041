import React from 'react';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import MasonryGrid from './components/masonry-grid/masonry-grid';

const widgets = [
  { title: 'Widget 1', content: '4x1', colSpan: 4, rowSpan: 1 },
  { title: 'Widget 2', content: '1x2 widget', colSpan: 1, rowSpan: 2 },
  { title: 'Widget 3', content: '1x2 widget', colSpan: 1, rowSpan: 2 },
  { title: 'Widget 4', content: '1x2 widget', colSpan: 1, rowSpan: 2 },
  { title: 'Widget 5', content: '1x1 widget', colSpan: 1, rowSpan: 2 },
  { title: 'Widget 6', content: '2x2 widget', colSpan: 1, rowSpan: 1 },
  { title: 'Widget 7', content: '4x4 widget', colSpan: 2, rowSpan: 2 },
  { title: 'Widget 8', content: '1x1 widget', colSpan: 1, rowSpan: 1 },
  { title: 'Widget 9', content: '1x1 widget', colSpan: 1, rowSpan: 1 },
  { title: 'Widget 10', content: '1x1 widget', colSpan: 1, rowSpan: 1 },
];

const Home = () => (
  <ResponsiveWrapper customClass="sm:max-w-[880px]">
    <div className="my-5 font-black font-family text-4xl text-indigo-02">
      Welcome to MARKETview
    </div>
    <MasonryGrid widgets={widgets} />
  </ResponsiveWrapper>
);

export default Home;
