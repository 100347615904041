import React, { useRef, useEffect, useState } from 'react';
import {
  generateLayoutMap,
  mapLayout,
} from 'containers/user/home/components/masonry-grid/masonry-grid.helper';

import { useResponsiveColumns } from 'containers/user/home/components/masonry-grid/hooks/use-responsove-columns';

const MasonryGrid = ({ widgets }) => {
  const gridRef = useRef(null);
  const columnWidth = 280;
  const rowHeight = 150;
  const maxColumns = 4;

  const columnCount = useResponsiveColumns(gridRef, columnWidth, maxColumns);

  const [layout, setLayout] = useState([]);

  useEffect(() => {
    const { layoutMap, rowCount } = generateLayoutMap(widgets, columnCount);
    const finalLayout = mapLayout(widgets, layoutMap, columnCount, rowCount);

    setLayout(finalLayout);
  }, [widgets, columnCount]);

  return (
    <div
      ref={gridRef}
      className="grid gap-4 p-4 mx-auto"
      data-testid="masonry-grid"
      style={{
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        gridAutoRows: `${rowHeight}px`,
      }}
    >
      {layout.map(({ key, title, content, colSpan, rowSpan }) => (
        <div
          key={key}
          className="masonry-item bg-gray-100 p-4 rounded-lg shadow-md flex flex-col justify-center items-center text-center"
          style={{
            gridColumn: `span ${colSpan}`,
            gridRow: `span ${rowSpan}`,
          }}
        >
          <h2 className="text-lg font-bold">{title}</h2>
          <p>{content}</p>
        </div>
      ))}
    </div>
  );
};

export default MasonryGrid;
